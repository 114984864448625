import React from "react";

export default function Navbar() {
  let imgUrl = "../assests/mainLogo.jpg";

  // const scrollToFooter = () => {
  //   const footer = document.getElementById("footer");
  //   if (footer) {
  //     const top = footer.offsetTop;
  //     window.scrollTo({
  //       top: top,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  return (
    <div className="navBar">
      <div className=" logo-container  mt-2">
        <img className="logoHeight" src={imgUrl} alt=""></img>
      </div>
      {/* <div></div>
      <div className="mt-2">
        <button
          type="button"
          onClick={scrollToFooter}
          className="btn"
          style={{ height: "72px", width: "128px" }}
        >
          Contact Us <i class="fa fa-angle-right" aria-hidden="true"></i>
        </button>
      </div> */}
    </div>
  );
}
