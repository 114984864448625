import React from "react";

const AboutUs = () => {
  return (
    <div className="AboutSec mt-3">
      <div className="About">
        <div>
          <p
            style={{
              backgroundColor: "#75C5DA4D",
              borderRadius: "20px ",
              height: "28px",
              width: "80px",
              color: "#88C7D9",
            }}
          >
            About
          </p>
        </div>
        <div>
          <h3 style={{ color: "#195B61" }}>Know more about us</h3>
        </div>
      </div>
      <div className="row m-2">
        <div className="aboutImageAndText col-lg-6 col-md-6 col-sm-12">
          <img src="./assests/aboutUs.jpg" alt="" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="mt-3 mobileViewAboutUs">
            <ul className="nav nav-tabs" role="tablist" id="aboutUl">
              <li className="nav-item col-4" role="presentation">
                <a
                  className="nav-link active"
                  id="simple-tab-0"
                  data-bs-toggle="tab"
                  href="#simple-tabpanel-0"
                  role="tab"
                  aria-controls="simple-tabpanel-0"
                  aria-selected="true"
                >
                  About Us
                </a>
              </li>
              <li className="nav-item col-4" role="presentation">
                <a
                  className="nav-link"
                  id="simple-tab-1"
                  data-bs-toggle="tab"
                  href="#simple-tabpanel-1"
                  role="tab"
                  aria-controls="simple-tabpanel-1"
                  aria-selected="false"
                >
                  Our Vision
                </a>
              </li>
              <li className="nav-item col-4" role="presentation">
                <a
                  className="nav-link"
                  id="simple-tab-2"
                  data-bs-toggle="tab"
                  href="#simple-tabpanel-2"
                  role="tab"
                  aria-controls="simple-tabpanel-2"
                  aria-selected="false"
                >
                  Our Belief
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content pt-2" id="tab-content">
            <div
              className="tab-pane active"
              id="simple-tabpanel-0"
              role="tabpanel"
              aria-labelledby="simple-tab-0"
              style={{ textAlign: "left" }}
            >
              Bright Health Care is a leading surgical product manufacturer in
              India, known for our wide range of high-quality instruments and
              goods. Our reputation for excellence stems from our dedication to
              providing top-tier products that meet the stringent standards of
              the medical industry. From precision surgical tools to essential
              medical supplies, we offer everything needed for successful
              procedures and patient care. <br />
              <br /> At Bright Health Care, we understand the importance of
              quality in healthcare. That's why our products are trusted by
              surgeons, hospitals, and healthcare facilities nationwide. With
              our commitment to innovation and reliability, we continue to set
              the standard for excellence in the surgical products industry.
            </div>
            <div
              className="tab-pane"
              id="simple-tabpanel-1"
              role="tabpanel"
              aria-labelledby="simple-tab-1"
              style={{ textAlign: "left" }}
            >
              Bright Health Care is dedicated to providing top-quality surgical
              products at competitive prices, ensuring accessibility and
              excellence. Our goal is sustainable growth and global recognition
              through innovation and a steadfast commitment to quality.
            </div>
            <div
              className="tab-pane"
              id="simple-tabpanel-2"
              role="tabpanel"
              aria-labelledby="simple-tab-2"
              style={{ textAlign: "left" }}
            >
              At Bright Health Care, we prioritize spreading happiness through
              quality products, meeting and exceeding customer expectations, and
              ensuring customer satisfaction. It's our commitment to deliver
              excellence and joy in every interaction.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
