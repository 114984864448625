import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const testimonials = [
    {
      name: "MediCareInsights",
      stars: "★★★★★",
      review:
        "I've been using this digital thermometer for a few months now, and it's been incredibly reliable. The readings are fast and accurate, which is essential, especially when monitoring fever. The display is clear and easy to read, even in low light.Highly recommended!",
    },
    {
      name: "WellBeingReviewer",
      stars: "★★★★☆",
      review:
        "As an active individual, I often experience minor injuries that require bandaging. This elastic adhesive bandage has been a lifesaver! It's flexible enough to move with my body without feeling restrictive, yet it provides excellent support and compression.",
    },
    {
      name: "HealthWatchReviewer",
      stars: "★★★★★",
      review:
      "Managing my medical condition requires frequent infusions, and the Care Well infusion set has made the process much more manageable. The tubing is flexible and kink-resistant, ensuring a smooth flow of medication. The insertion process is virtually painless, thanks to the ultra-thin needle and smooth cannula."
    },
  ];

  return (
    <div className=" my-4">
      <div className="testimonials">
        <div className="About mt-3">
          <div>
            <p
              style={{
                backgroundColor: "#75C5DA4D",
                borderRadius: "20px ",
                height: "28px",
                width: "100px",
                color: "#88C7D9",
              }}
            >
              Testimonials
            </p>
          </div>
          <div>
            <h3 style={{ color: "#195B61" }}>
              See What People Says About Us!!
            </h3>
          </div>
          <div>
            <span
              style={{
                color: "#75C5DA",
                fontFamily: "Lato",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
             Hear from Satisfied Customers About Their Experience!!
            </span>
          </div>
        </div>
      </div>

      <div className=" d-flex flex-wrap justify-content-center align-item-center mt-2">
        <div className="row col-lg-12">
          {testimonials.map((data, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12 mt-3" key={index}>
              <div className="testimonial-card mx-3 testimoniaard" >
                <h2 className="name">{data.name}</h2>
                <div className="stars">{data.stars}</div>
                <p className="review">{data.review}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
