import React from "react";

const MiddleSection = () => {
  return (
    <div className="middleSec col-xl-4 col-lg-10 col-md-8 col-sm-4">
      <img
        src="../assests/middleeSec.jpg"
        alt=""
        className=" firstImg mainDivFirstImage"
      ></img>
      <img
        src="../assests/mobileViewMain.jpg"
        alt=""
        className="mainDivSecondImage"
      ></img>
    </div>
  );
};

export default MiddleSection;
