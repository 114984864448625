import React ,{ useState } from 'react';

const Footer1 = () => {
  const [scrollToTop, setScrollToTop] = useState(false);

  const handleScrollToTop = () => {
    setScrollToTop(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <footer className="footersite py-3">
      <div class="container">
        <div className="row ">
          <div class="row col-lg-10 col-md-10 col-sm-10 ">
            <div className=" col-lg-4 col-md-4 mb-1">
              <img
                src="../assests/mainLogo.jpg"
                alt=""
                className="imgLogo"
              ></img>
              {/* <p className="mt-3">RARR Technologies Pvt. Ltd. is an industry leading software development company building digital products that lasts. By being reasonable product craftsmen, we're able to avoid surprises and focus on the quality of the software and deliver.</p> */}
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="mobile">
                <p className="contact mb-1 mt-1">Contact us</p>
                <p className="contact-type">
                  +919416080100, 9728341111, 9729000419
                </p>
              </div>
              <div className="email">
                <p className="contact mb-1 mt-1">Email</p>
                <p className="contact-type">britehealthcarehelp@gmail.com</p>
              </div>
            </div>
            <div className=" col-lg-4 col-md-4">
              <div className="address">
                <p className="contact mb-1 mt-1">Our Address</p>
                <p className="contact-type">
                  Plot No. 101, Tirana Mod bhiwani - Pin 127021
                </p>
              </div>
              <div className="timings">
                <p className="contact mb-1 mt-1">Opening Hours</p>
                <p className="contact-type">9am - 7pm</p>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-2">
            <button
              className="btn"
              onClick={handleScrollToTop}
              style={{ backgroundColor: "#D4D5D9", borderRadius: "50%",border:"0px" }}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p className="mb-0" style={{ color: "#717171" }}>
          © 2024 All Rights Reserved. Design & Developed By Brite HealthCare.
        </p>
      </div>
    </footer>
  );
};

export default Footer1;
