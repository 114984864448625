import React from "react";

const Faq = () => {
  return (
    <div className=" testimonialsDiv mb-4 ">
      <div className=" testimonials row  m-auto">
        <div className="About mt-3">
          <div>
            <p
              style={{
                backgroundColor: "#75C5DA4D",
                borderRadius: "20px ",
                height: "28px",
                width: "70px",
                color: "#88C7D9",
              }}
            >
              FAQ
            </p>
          </div>
          <div>
            <span
              style={{
                color: "#195B61",
                fontSize: "30px",
                fontWeight: "600",
                lineHeight: "50px",
              }}
            >
              Have Questions in Mind? We got you covered !!
            </span>
          </div>
        </div>

        <div className="About mb-3">
          <div class="accordion open col-10" id="accordionExample">
            <div class="accordion-item mb-3 item">
              <h2
                class="accordion-header  "
               
              >
                <button
                  class="accordion-button custom-accordion-header"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Is it safe to use a heating bag while sleeping ?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" style={{ visibility: "visible" }}>
                  <h1  className="faqText" style={{ textAlign: "left" }}>
                    While many heating bags are designed for safe overnight use,
                    it's essential to follow the manufacturer's instructions and
                    use precautions to avoid overheating or burns. Ensure the
                    heating bag has an automatic shut-off feature and never
                    leave it unattended while in use.
                  </h1>
                </div>
              </div>
            </div>
            <div class="accordion-item mb-3 item">
              <h2 class="accordion-header " >
                <button
                  class="accordion-button collapsed custom-accordion-header"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How do I use a digital thermometer ?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" style={{ visibility: "visible" }}>
                  <h1  className="faqText" style={{ textAlign: "left" }}>
                    Using a digital thermometer is simple. Place the thermometer
                    tip under the tongue, in the armpit, or rectally, depending
                    on the type. Wait for the beep or signal indicating the
                    temperature has been measured, then read the display for the
                    reading.
                  </h1>
                </div>
              </div>
            </div>
            <div class="accordion-item item">
              <h2 class="accordion-header r">
                <button
                  class="accordion-button collapsed custom-accordion-header"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How should I dispose of used gauze swabs ?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" style={{ visibility: "visible" }}>
                  <h1 className="faqText" style={{ textAlign: "left" }}>
                    Used gauze swabs should be disposed of properly to prevent
                    contamination and infection spread. Place them in a sealed
                    plastic bag before disposing of them in a designated medical
                    waste bin. Follow local regulations for safe disposal
                    practices.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
