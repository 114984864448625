import "./App.css";
 import Placecard from "./components/Placecard";
import Navbar from "./components/Navbar";
 import MiddleSection from "./components/MiddleSection";
 import AboutUs from "./components/AboutUs";
import Footer1 from "./components/Footer1";
import Testimonials from "./components/Testimonials" ; 
import Faq from "./components/Faq";
function App() {
  return (
    <div className="App">
      <Navbar></Navbar>
     <MiddleSection></MiddleSection>
     <AboutUs />
     {/* <Testimonial1/> */}
     <Placecard />
        <Testimonials />
      <Faq/>
       {/* footer 1 is responsive change content only  */}
       <Footer1/>
    </div>
  );
}

export default App;
