import React, { useState } from "react";

function Placecard() {
  const [showAll, setShowAll] = useState(false);
  const initialCardCount = 9; // Number of cards to display initially

  const handleViewMore = () => {
    setShowAll(true);
  };

  const handleViewLess = () => {
    setShowAll(false);
  };

  const cardData = [
    {
      id: "1",
      image: "./assests/heatingBag.JPG",
      title: "HEATING BAG",
      subtitle: "Technology For Healthy Life",
      content: [
        { heading: "Electronic Heating Bag" },
        { heading: "Material Velvet Cloth" },
        { heading: "Voltage AC 220V" },
        { heading: "Frequency 50Hz" },
        { heading: "Charging time 8-12 minutes" },
      ],
    },
    {
      id: "2",
      image: "./assests/warnBag.JPG",
      title: "WARM BAG",
      subtitle: "Velvet",
      content: [
        { heading: "Easy to Carry" },
        { heading: "Eliminates Muscle Fatigue" },
        { heading: "Easy to Use" },
        { heading: "No Hassle of Heating Water Manually" },
      ],
    },
    {
      id: "3",
      image: "./assests/digitalThermMeterOral.JPG",
      title: "DIGITAL THERMOMETER",
      subtitle: "Oral - Rectal - Underam",
      content: [
        { heading: "Clinically Tested & Highly Accurate" },
        { heading: "With Fever Alert" },
        { heading: "Auto Switch Off" },
        { heading: "Non Mercury(100% Safe)" },
        { heading: "Scale Temp. in F & C" },
      ],
    },
    {
      id: "4",
      image: "./assests/infusion set.png",
      title: "CARE WEL INFUSION SET",
      subtitle: "Caution : ",
      content: [
        { heading: "Do not use for blood or its derivatives" },
        { heading: "Discar after single use." },
        { heading: "Gravity feed ony Needle size 21G." },
        { heading: "Don't use if package is open or damaged" },
      ],
    },
    {
      id: "5",
      image: "./assests/blood pressure.png",
      title: "BLOOD PRESSURE",
      subtitle: "Moniter With USB Port",
      content: [
        { heading: "One Touch Operation" },
        { heading: "Beeper On" },
        { heading: "2x90 Memory" },
        { heading: "Jumbo Display" },
      ],
    },
    {
      id: "6",
      image: "./assests/elasticAdhesiveBandage.JPG",
      title: "ELASTIC ADHESIVE BANDAGE",
      subtitle: "Ortho Band",
      content: [
        { heading: "Hypoallergenic" },
        { heading: "Highly Permeable to Air & Water Vapour" },
        { heading: "Optimum Adhesion" },
        { heading: "Painless Removal" },
      ],
    },
    {
      id: "7",
      image: "./assests/fixer.png",
      title: "Q-FIX I.V. CANNULA FIXER",
      subtitle: "Features :",
      content: [
        { heading: "Hypoallergenic" },
        { heading: "Highly Permeable to Air & Water Vapour" },
        { heading: "Optimum Adhesion" },
        { heading: "Painless Removal" },
      ],
    },
    {
      id: "8",
      image: "./assests/GauzeSwab.JPG",
      title: "GAUZE SWAB",
      subtitle: "Gauze Swab Sterile Absorbant",
      content: [
        { heading: "Hypoallergenic" },
        { heading: "Highly Permeable to Air & Water Vapour" },
        { heading: "Optimum Adhesion" },
        { heading: "Painless Removal" },
      ],
    },
    {
      id: "9",
      image: "./assests/cast padding.png",
      title: "CAST PADDING",
      subtitle: "15 cm x 3 mm",
      content: [{ heading: "Use as Soft Padding Under Hand Plaster Cast" }],
    },

    {
      id: "10",
      image: "./assests/bloodLenset.JPG",
      title: "Flat BLOOD LANCETS",
      subtitle: "Orthocrepe With Fast Edges",
      content: [
        { heading: "Hypoallergenic" },
        { heading: "Highly Permeable to Air & Water Vapour" },
        { heading: "Optimum Adhesion" },
        { heading: "Painless Removal" },
      ],
    },

    {
      id: "11",
      image: "./assests/cottonCrepeBandage.JPG",
      title: "COTTON CREPE BANDAGE",
      subtitle: "Orthocrepe With Fast Edges",
      content: [
        {
          heading:
            "Used for varicose veins,strains,paindful joints and as a pressure bandage in burns and skin grafts.",
        },
        {
          heading:
            "Rinse in fresh tap water Squeeze gently and allow to dry without streching and Wash carefully in hot soapy water.",
        },
      ],
    },
    {
      id: "12",
      image: "./assests/airNebNebulizer.JPG",
      title: "CAREWELL AIR NEB",
      subtitle: "Durable Pistol Nebulizer",
      content: [
        { heading: "Complete Nebulizer Kit Included" },
        { heading: "Ideal For All Ages" },
        { heading: "Latex Free" },
        { heading: "Light Weight & Easy To Clean" },
      ],
    },
    {
      id: "13",
      image: "./assests/digitalBathroomScale.JPG",
      title: "DIGITAL BATHROOM SCALE",
      subtitle: "Don't Wait To Lose Weight",
      content: [
        { heading: "Step -on Technology" },
        { heading: "Extra-large Lcd Display" },
        { heading: "Ultra Slim & Stylish" },
        { heading: "Capacity 180 kg." },
      ],
    },

    {
      id: "14",
      image: "./assests/briterope.png",
      title: "BRITEPORE",
      subtitle: "Microporous Surgical Tape",
      content: [
        { heading: "Hypoallergenic" },
        { heading: "Highly Permeable to Air & Water Vapour" },
        { heading: "Optimum Adhesion" },
        { heading: "Painless Removal" },
      ],
    },
    {
      id: "15",
      image: "./assests/latexGloves.JPG",
      title: "LATEX EXAMINATION GLOVES",
      subtitle: "Complete Hand Protection",
      content: [
        { heading: "Power Fed" },
        { heading: "Fits Either Hand" },
        { heading: "Single Use Only" },
      ],
    },
    {
      id: "16",
      image: "./assests/handloom bandage.png",
      title: "HANDLOOM ROLLED BANDAGE",
      subtitle: "Features :",
      content: [
        { heading: "Stretches With Body Movements" },
        { heading: "Allows Air Circulation" },
        { heading: "Prevents Infusion Related Phlebitis" },
        { heading: "Helps in Preventing Pain" },
      ],
    },

    {
      id: "17",
      image: "./assests/plaster of paris.png",
      title: "PLASTER OF PARIS BANDAGE",
      subtitle: "Plaster",
      content: [{ heading: "Fine Quality" }],
    },
    {
      id: "18",
      image: "./assests/absorentCottonWool.JPG",
      title: "ABSORBENT COTTON WOOL I.P.",
      subtitle: "Features :",
      content: [
        { heading: "Store In A Dry Place " },
        { heading: "Away From Heat & Sun" },
        { heading: "Fine Quality" },
      ],
    },
  ];

  const cardsToShow = showAll ? cardData : cardData.slice(0, initialCardCount);

  return (
    <>
      <div className="About mt-5">
        <div>
          <p
            style={{
              backgroundColor: "#75C5DA4D",
              borderRadius: "20px ",
              height: "28px",
              width: "100px",
              color: "#88C7D9",
            }}
          >
            Our Products
          </p>
        </div>
        <div>
          <h3 style={{ color: "#195B61" }}>Our Products</h3>
        </div>
        <div>
          <span
            style={{
              color: "#75C5DA",
              fontFamily: "Lato",
              fontWeight: "400",
              fontSize: "14px",
            }}
          >
            Discover a Wide Range of Reliable Goods for Every Need!!
          </span>
        </div>
      </div>
      <div className="mediaCss container">
        <div className="row  m-auto ">
          {cardsToShow.map((data, index) => (
            <div className="col-lg-4  col-md-6 col-sm-12 mt-4">
              <div
                className="cardHeight mobileViewCardHeight col-lg-4  col-md-6 col-sm-12  py-3"
                key={index}
              >
                <div className="cardImage p-3 m-auto">
                  <img
                    className="img-fluid "
                    src={data.image}
                    alt=""
                    style={{
                      boxShadow:
                        " 0px 4px 6px 6px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
                    }}
                  />
                </div>
                <div className="headingSec px-3 mt-3">
                  <h3 className="cardTitle mobileViewCardTitle">{data.title}</h3>
                  <h5 className="cardSubTitle">{data.subtitle}</h5>
                </div>
                <div className="featureSec list-inside px-4">
                  <ul className="list-disc">
                    {data.content.map((contentHeading, i) => (
                      <li
                        key={i}
                        className="liContent"
                        style={{ marginLeft: "1rem" }}
                      >
                        {contentHeading.heading}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
          <div className="m-auto">

          {!showAll && cardData.length > initialCardCount && (
            <button onClick={handleViewMore} className="btn viewMore mt-3">
              View More
            </button>
          )}
          {showAll && (
            <button onClick={handleViewLess} className="btn viewMore mt-3">
              View Less
            </button>
          )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Placecard;
